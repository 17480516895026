<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HY_list' }">重要会议</el-breadcrumb-item>
      <el-breadcrumb-item>会议详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="wrap">
      <div class="h1">
        放射影像专业质控新进展：强质控、促交流、启新章
      </div>
      <div class="h2">
        国家级放射影像专业质控中心X线技术亚专业组第二次会议成功举行
      </div>
      <section>
        <div class="div_p">
          随着医疗技术的飞速发展，影像学检查已成为临床诊断不可或缺的重要手段。如何确保影像检查质量和规范，提升区域影像检查的同质互认，已成为影像质量管理的关键议题之一。2024年8月27日下午，国家级放射影像专业质控中心X线技术亚专业组第二次会议顺利召开，专家医技结合齐聚云端，共议未来X线检查规范和质控工作的新方向和新思路。
        </div>
        <div class="div_p">
          国家级放射影像专业质控中心主任委员金征宇教授、副主任委员薛华丹教授、秘书孙昊教授和于静老师亲临指导，本次会议同时特别邀请中华医学会影像技术分会前任主任委员、国家级放射影像专业质控中心委员付海鸿教授、中华医学会影像技术分会儿科影像技术学组组长吴爱琴教授进行精彩分享，X线技术亚专业组全体专家成员参加会议。本次会议各方向负责人进行工作总结的同时，与参会专家围绕“行业规范的调研”展开了深入交流与讨论。
        </div>
        <div class="div_p">
          会议上，国家放射影像专业质控中心主任委员金征宇教授对X线亚专业组前期工作给予了认可和鼓励。讲话中指出X线是影像检查中最早最成熟的技术，也是影像检查容易互认的项目。再次强调国家级质控中心的工作是政府部门工作的延伸，将横向联合各省质控中心，纵向依托各亚专业组来开展工作。X线检查行业规范的制定是未来质量控制工作的基础，可以吸引更多影像技术团队的专家加入，医技密切结合深入推进工作。
        </div>
        <div class="div_p">
          国家级放射影像质控中心委员、X线技术亚专业组组长张惠茅教授，首先，对国家级指控中心领导的莅临和特邀嘉宾付海鸿主委和吴爱琴主任影像技术专家团队的亲情支持表示诚挚的谢意。对亚专业组近两个月以来大家共同努力而取得的阶段性成果表示感谢。其次，对近期工作进行了简单回顾，张惠茅教授指出要注重检查规范的实用性、科学性和专科性。同时与各位专家商讨下一步的工作计划未来工作，完成X线培训视频的录制，采用德尔菲法优化调研问卷及进一步明确行业规范如何开展。
        </div>
        <div class="div_p">
          本次大会的特邀嘉宾中华医学会影像技术分会前主任委员付海鸿教授总结了DR摄影质控难点，分享了零到六岁儿童基于DR设备的规范化成像的共识，同时对于受检者的设备、体位设计以及成像参数的选择、辐射剂量参数的参考就诊断参考水平及影像质控要点等进行规范化的要求。
        </div>
        <div class="div_p">
          会议上X线亚专业组6位副组长积极分享各方向的工作进展，头颈方向负责人王荣品教授、心胸方向负责人孙文阁教授、腹部方向负责人柳林教授、骨课方向负责人徐文坚教授、
          儿科方向负责人刘怀军教授、骨密度方向负责人程晓光教授分别介绍了各方向行业规范的调研总结，并给出了宝贵的专业建议。乳腺X线检查负责人顾雅佳教授介绍了工作进展以及明确了培训视频录制工作的时间截点。
        </div>
        <div class="div_p">
          在讨论环节，专家纷纷就感兴趣的问题进行了交流，吴爱琴教授分享了儿科影像检查规范化共识撰写的经验，同时，对辐射剂量、多部位联合摄影、脊柱侧弯畸形、骨龄AI辅助诊断等方面给出了规范化需求。国家级放射影像质控中心副主任薛华丹教授传达国家卫健委对国家级质控中心的工作部署，同时响应国家卫健委号召，希望减少基层医院调研工作负担，在，并且建议亚专业组可参考国家级质控中心凝练汇总各省互认检查项目清单后优先侧重互认检查规范的制订。
        </div>
        <div class="div_p">
          近3个小时的亚专业会议，热烈且充实，在国家级放射影像专业质控中心的引导下，在中华医学会影像技术分会专家的倾情协助下，X线亚专业组致力于提出切实可行的影像检查的检查规范和质控指标，以确保患者能够接受到更加准确和安全的医疗服务。随着质控相关工作的不断推进落实，我们期待X线检查检查将更加规范化、标准化，为临床诊断和影像互认提供更加有力的支持，开启影像质控的新篇章。
        </div>
        <div>
          <img src="@/assets/HY20240827/1.png" alt="">
        </div>
        <div>
          <img src="@/assets/HY20240827/2.png" alt="">
        </div>
        <div class="div_tit">
          全体参会人员
        </div>
        <div>
          <img src="@/assets/HY20240827/3.png" alt="">
        </div>
        <div class="div_tit">
          国家级放射影像专业质控中心主任委员 金征宇教授
        </div>
        <div>
          <img src="@/assets/HY20240827/4.png" alt="">
        </div>
        <div class="div_tit">
          国家级放射影像专业质控中心专家委员会委员 X线技术专业组组长 张惠茅教授
        </div>
        <div>
          <img src="@/assets/HY20240827/5.png" alt="">
        </div>
        <div class="div_tit">
          中华医学会影像技术分会前任主任委员、国家级放射影像专业质控中心委员付海鸿教授
        </div>
        <div>
          <img src="@/assets/HY20240827/5-1.png" alt="">
        </div>
        <div class="div_tit">
          国家级放射影像专业质控中心副主任委员 薛华丹教授、孙昊教授
        </div>
        <div class="img-list">
          <div>
            <img src="@/assets/HY20240827/6.png" alt="">
            <img src="@/assets/HY20240827/7.png" alt="">
          </div>
          <div>
            <img src="@/assets/HY20240827/8.png" alt="">
            <img src="@/assets/HY20240827/9.png" alt="">
          </div>
          <div>
            <img src="@/assets/HY20240827/10.png" alt="">
            <img src="@/assets/HY20240827/11.png" alt="">
          </div>
          <div>
            <img src="@/assets/HY20240827/12.png" alt="">
            <img src="@/assets/HY20240827/13.png" alt="">
          </div>
        </div>
        <div class="div_p">
          头颈方向负责人王荣品教授、心胸方向负责人孙文阁教授、腹部方向负责人柳林教授、骨课方向负责人徐文坚教授、儿科方向负责人刘怀军教授、骨密度方向负责人程晓光教授、乳腺X线检查负责人顾雅佳教授分别介绍工作进展，吴爱琴教授分享儿童胸部X线检查规范
        </div>

      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "HY20240827",
  data() {
    return {
    }
  },

}
</script>

<style scoped>
.wrap {
  max-width: 677px;
  margin: 40px auto;
  color: rgb(0, 0, 0);
  background: #fff;
  padding: 10px;
  line-height: 2;
}

img {
  width: 100%;
  box-sizing: border-box;
  margin: 10px auto;
  display: block;
}
.img-list img {
  display: inline;
  width: 50%;
  margin: 0;
}

.h1 {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}

.h2 {
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}

.div_p {
  text-indent: 2.125em;
  white-space: normal;
  margin: 0px;
  padding: 10px;
  box-sizing: border-box;
  visibility: visible;
}

.div_tit {
  text-align: center;
}
</style>